import _JSXStyle from 'styled-jsx/style'
import { Drawer, Modal } from 'antd'
import { useEffect, useContext } from 'react'
import classNames from 'classnames'
import moment from 'moment'
import { AppContext } from './Layout'
import { Media } from '../media'

export const FoodUnavailable = () => {
  const {
    theme,
    openFoodNotAvailable,
    setOpenFoodNotAvailable,
    unavailableItem,
    setMealDate,
    setUnavailableItem,
    setMealType,
    timeZone
  } = useContext(AppContext)

  const dateChangeHandler = (data: any) => {
    const currentDate = moment(new Date(data?.actual)).utc()
    const d = currentDate.toString()
    const splittedDate = d.split(' ')
    const date = `${splittedDate[2]},${splittedDate[1]} '${splittedDate[3].slice(-2)}`
    setMealDate({
      originalDate: `${splittedDate[2]} ${splittedDate[1]} '${splittedDate[3].slice(-2)}`,
      modifiedDate: date,
      isoString: currentDate.toISOString()
    })

    if (data?.modified?.split('|')?.[1]?.trim() !== 'null') {
      setMealType({
        type: data?.modified?.split('|')?.[1]?.trim(),
        timeSlot: `${moment(moment(data?.actual).utc(), 'HH:mm A')?.format('hh:mm A')} to ${moment(
          moment(data?.endTime).utc(),
          'HH:mm A'
        )?.format('hh:mm A')}`,
        startTime: `${moment(data?.actual)?.utc().format('HH:mm:ss')}.000Z`,
        endTime: `${moment(data?.endTime).utc().format('HH:mm:ss')}.000Z`,
        isPickedTimeSlot: false
      })
    }
    setOpenFoodNotAvailable(false)
  }

  useEffect(() => {
    return () => setUnavailableItem()
  }, [])

  return (
    <>
      {unavailableItem && (
        <>
          <Media at="sm">
            <Drawer
              className="rounded-t-[12px]"
              placement="bottom"
              title="We're sorry!"
              onClose={() => setOpenFoodNotAvailable(false)}
              open={openFoodNotAvailable}
              height={300}
            >
              <div className="w-[100%] px-[24px]">
                <div className="mb-[16px] flex flex-wrap items-center">
                  <div className="pr-[3px] font-book text-md font-medium">We are not cooking</div>
                  <div className="pr-[3px] font-black text-md font-[500]">{` ${unavailableItem?.item?.title} `}</div>
                  <div className="pr-[3px] font-book text-md font-medium">for</div>
                  <div className="font-black text-md font-[500]">{`'${moment(new Date())
                    .utcOffset(timeZone)
                    ?.format('DD MMM')}'`}</div>
                </div>
                <>
                  {unavailableItem?.datesArray?.length ? (
                    <>
                      <div className="font-book text-md font-medium not-italic text-[#000000]">
                        {`Choose a nearest available date/time for ${unavailableItem?.item?.title}`}
                      </div>
                      <div className="mt-[8px] flex justify-start space-x-2">
                        {unavailableItem?.datesArray?.map(
                          (item: any, index: any) =>
                            index >= 0 &&
                            index < 3 && (
                              <div
                                className={classNames(
                                  'mt-[8px] w-fit border-[1px] px-[6px] py-[4px] text-center font-book text-sm font-medium text-[#000000]'
                                )}
                                style={{ borderColor: theme.customPrimary }}
                                onClick={() => dateChangeHandler(item)}
                              >
                                {item?.modified?.split('|')?.[1]?.trim() === 'null'
                                  ? item?.modified?.split('|')?.[0]?.trim()
                                  : item?.modified}
                              </div>
                            )
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="font-book text-md font-medium not-italic text-[#000000]">
                      {`No schedules found for ${unavailableItem?.item?.title}`}
                    </div>
                  )}
                </>
                <div
                  className="mt-[23.5px] cursor-pointer text-md font-[700]"
                  style={{ color: theme.customPrimary }}
                  onClick={() => {
                    const d = moment(new Date()).utcOffset(timeZone)?.toString()

                    const splittedDate = d.split(' ')
                    const date = `${splittedDate[2]},${splittedDate[1]} '${splittedDate[3].slice(
                      -2
                    )}`
                    setMealDate({
                      originalDate: `${splittedDate[2]} ${splittedDate[1]} '${splittedDate[3].slice(
                        -2
                      )}`,
                      modifiedDate: date,
                      isoString: `${moment(new Date())
                        .utcOffset(timeZone)
                        .format('YYYY-MM-DDTHH:mm:ss')}.000Z`
                    })
                    setOpenFoodNotAvailable(false)
                  }}
                >
                  Browse other dishes for{' '}
                  {`'${moment(new Date()).utcOffset(timeZone)?.format('DD MMM')}'`}
                </div>
              </div>
            </Drawer>
          </Media>
          <Media greaterThan="sm">
            <Modal
              centered
              open={openFoodNotAvailable}
              onCancel={() => setOpenFoodNotAvailable(false)}
              footer={null}
            >
              <div className="w-[100%] p-[24px] px-[24px]">
                <div
                  className="mb-[10px] -mt-[5px] font-black text-[20px] font-[500]"
                  style={{ color: theme.customPrimary }}
                >
                  We’re sorry!
                </div>
                <div className="mb-[16px] flex flex-wrap items-center">
                  <div className="pr-[3px] font-book text-md font-medium">We are not cooking</div>
                  {/* <div className="pr-[3px] font-black text-md font-[500]"> </div> */}
                  <div className="pr-[3px] font-black text-md font-[500]">{` ${unavailableItem?.item?.title} `}</div>
                  <div className="pr-[3px] font-book text-md font-medium">for</div>
                  <div className="font-black text-md font-[500]">{`'${moment(new Date())
                    .utcOffset(timeZone)
                    ?.format('DD MMM')}'`}</div>
                </div>
                <>
                  {unavailableItem?.datesArray?.length ? (
                    <>
                      <div className="font-book text-md font-medium not-italic text-[#000000]">
                        {`Choose a nearest available date/time for ${unavailableItem?.item?.title}`}
                      </div>
                      <div className="mt-[8px] flex flex-wrap">
                        {unavailableItem?.datesArray?.map(
                          (item: any, index: any) =>
                            index >= 0 &&
                            index < 3 && (
                              <div
                                className={classNames(
                                  'border-[1px] px-[6px] py-[4px] text-center font-book text-sm font-medium text-[#000000]',
                                  index > 0 && 'ml-[8px]'
                                )}
                                style={{ borderColor: theme.customPrimary }}
                                onClick={() => dateChangeHandler(item)}
                              >
                                {item?.modified?.split('|')?.[1]?.trim() === 'null'
                                  ? item?.modified?.split('|')?.[0]?.trim()
                                  : item?.modified}
                              </div>
                            )
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="font-book text-md font-medium not-italic text-[#000000]">
                      {`No schedules found for ${unavailableItem?.item?.title}`}
                    </div>
                  )}
                </>
                <div
                  className="mt-[23.5px] cursor-pointer text-md font-[700]"
                  style={{ color: theme.customPrimary }}
                  onClick={() => {
                    const d = moment(new Date()).utcOffset(timeZone)?.toString()

                    const splittedDate = d.split(' ')
                    const date = `${splittedDate[2]},${splittedDate[1]} '${splittedDate[3].slice(
                      -2
                    )}`
                    setMealDate({
                      originalDate: `${splittedDate[2]} ${splittedDate[1]} '${splittedDate[3].slice(
                        -2
                      )}`,
                      modifiedDate: date,
                      isoString: `${moment(new Date())
                        .utcOffset(timeZone)
                        .format('YYYY-MM-DDTHH:mm:ss')}.000Z`
                    })
                    setOpenFoodNotAvailable(false)
                  }}
                >
                  Browse other dishes for{' '}
                  {`'${moment(new Date()).utcOffset(timeZone)?.format('DD MMM')}'`}
                </div>
              </div>
            </Modal>
          </Media>
          <style jsx global>{`
            .ant-drawer .ant-drawer-header {
              border-bottom: 0px;
              padding-left: 24px;
              padding-right: 24px;
              padding-top: 24px;
              padding-bottom: 16px;
            }
          `}</style>
          <style jsx global>{`
            .ant-drawer .ant-drawer-title {
              font-family: Avenir-black;
              font-weight: 800;
              font-size: 20px;
              line-height: 27px;
              color: ${theme.customPrimary};
              left: 24px;
              position: absolute;
            }
          `}</style>
          <style jsx global>{`
            :where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-drawer'] [class^='ant-drawer'],
            :where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-drawer']
              [class^='ant-drawer'],
            :where(.css-dev-only-do-not-override-sk7ap8)[class^='ant-drawer']
              [class*=' ant-drawer'],
            :where(.css-dev-only-do-not-override-sk7ap8)[class*=' ant-drawer']
              [class*=' ant-drawer'] {
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
            }
          `}</style>
          <style jsx global>{`
            .ant-modal .ant-modal-title {
              box-shadow: 0px 5px 24px rgba(47, 94, 15, 0.1);
              color: ${theme.customPrimary};
            }
          `}</style>

          <style jsx global>{`
        .ant-modal .ant-modal-title {
          font-family: Avenir-black;
          font-size: 16px
          font-weight: 800;
          line-height: 22px;
          padding-top: 17px;
          padding-bottom: 17px;
          padding-left: 24px;
          color: ${theme.customPrimary};
        }
      `}</style>
          <style jsx global>{`
            .ant-modal .ant-modal-close-x {
              font-size: 15px;
              color: ${theme.customPrimary};
            }
          `}</style>
          <style jsx global>{`
        .ant-modal .ant-modal-close {
          position: absolute;
          top: 15.5px;
          right: 33.5px
          width: 0px;
          height: 0px;
        }
      `}</style>
          <style jsx global>{`
            .ant-modal .ant-modal-content {
              border-radius: 0px;
              padding: 0px;
            }
          `}</style>
        </>
      )}
    </>
  )
}
