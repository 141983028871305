import { notification } from 'antd'
import classNames from 'classnames'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'

import { AppContext } from './Layout'
import { ContainsAllergens } from './ContainsAllergens'
import { FiltersIconsHandler } from './FiltersIconsHandler'
import { getMealType } from '../utils/functions'
import { ImageWithBasePath } from './ImageWithBasePath'
import { LikeButton } from '../svgIcons/likeButton'
import { Media } from '../media'
import { NoOfItemsSelector } from './NoOfItemsSelector'
import { PrimaryButton } from './PrimaryButton'
import { PRODUCT } from '../ts/interfaces/product.interface'
import { useDeviceSize } from '../hooks/useDeviceSize'
import { CartItemProps } from '../ts/types/cartItem.types'
import { ProductSchedules } from '../ts/types/product.types'
import { NotificationType } from '../ts/types/all.types'

export const FoodCard = ({ foodDetails }: { foodDetails: PRODUCT }) => {
  const [showMore, setShowMore] = useState<boolean>(false)
  const [isContainAllergensOpen, setIsContainAllergensOpen] = useState<boolean>(false)
  const [width] = useDeviceSize()
  const {
    setShowCustomisationScreen,
    setCustomisationItem,
    setReCustomizeItem,
    cartItems,
    setOpenCustomisationModal,
    mealDate,
    mealType,
    setCartItems,
    setUnavailableItem,
    setOpenFoodNotAvailable,
    tenant,
    timeZone,
    theme,
    setMealDate,
    setMealType,
    setCheckProduct
  } = useContext(AppContext)

  const [totalItems, setTotalItems] = useState(0)
  const [api, contextHolder] = notification.useNotification()
  const openNotificationWithIcon = (type: NotificationType, message: string) => {
    api.destroy()
    api[type]({
      message,
      placement: 'top'
    })
  }

  const isPresent =
    cartItems.find((item: CartItemProps) => item?.foodDetails?.title === foodDetails?.title)
      ?.quantity || 0 > 0

  const primaryButtonHandler = async () => {
    if (mealDate?.isoString === '' && mealType?.type === '') {
      setCheckProduct(foodDetails?.title.replace(/\s/g, ''))
      const currentDate = moment(new Date()).utcOffset(timeZone)?.format('YYYY-MM-DD')
      const currentTime = moment(new Date()).utcOffset(timeZone)?.format('HH:mm:ss')
      for (let i = 0; i < foodDetails?.productSchedules?.length; i++) {
        const item = foodDetails?.productSchedules[i]
        const scheduleDate = moment(item?.scheduleStartTime).utc().format('YYYY-MM-DD')
        const scheduleTime = moment(item?.scheduleStartTime).utc().format('HH:mm:ss')
        const scheduleEndTime = moment(item?.scheduleEndTime).utc().format('HH:mm:ss')
        const scheduleCutoffTime = moment(item?.cutoffTime).utc().format('HH:mm:ss')
        if (
          scheduleDate === currentDate &&
          (currentTime < scheduleTime || currentTime < scheduleEndTime) &&
          currentTime < scheduleCutoffTime
        ) {
          const d = moment(new Date()).utcOffset(timeZone)?.toString()
          const splittedDate = d.split(' ')
          const date = `${splittedDate[2]},${splittedDate[1]} '${splittedDate[3].slice(-2)}`
          setMealDate({
            originalDate: `${splittedDate[2]} ${splittedDate[1]} '${splittedDate[3].slice(-2)}`,
            modifiedDate: date,
            isoString: `${moment(new Date())
              .utcOffset(timeZone)
              .format('YYYY-MM-DDTHH:mm:ss')}.000Z`
          })
          const meal = getMealType(
            item?.scheduleStartTime,
            item?.scheduleEndTime,
            tenant?.menuSlots
          )

          if (meal) {
            setMealType({
              type: meal,
              timeSlot: `${moment(moment(item?.scheduleStartTime).utc(), 'HH:mm A')?.format(
                'hh:mm A'
              )} to ${moment(moment(item?.scheduleEndTime).utc(), 'HH:mm A')?.format('hh:mm A')}`,
              startTime: `${moment(item?.scheduleStartTime)?.utc().format('HH:mm:ss')}.000Z`,
              endTime: `${moment(item?.scheduleEndTime).utc().format('HH:mm:ss')}.000Z`,
              isPickedTimeSlot: false
            })
            return
          } else {
            openNotificationWithIcon('error', 'Please choose the meal')
          }
          return
        }
      }

      let datesArray: any = {}
      foodDetails?.productSchedules?.map((item: ProductSchedules, index: number) => {
        const currentDate = moment(new Date()).format('YYYY-MM-DD')
        const scheduleDate = moment(item?.scheduleStartTime).utc().format('YYYY-MM-DD')
        if (scheduleDate > currentDate) {
          const meal = getMealType(
            item?.scheduleStartTime,
            item?.scheduleEndTime,
            tenant?.menuSlots
          )
          datesArray[index] = {
            actual: item?.scheduleStartTime,
            endTime: item?.scheduleEndTime,
            modified: `${moment(item?.scheduleStartTime).utc().format('DD MMM')} | ${meal}`
          }
        }
      })
      let sortedDates = Object.values(datesArray).sort((a: any, b: any): any => {
        if (a.actual > b.actual) return 1
        if (a.actual < b.actual) return -1
        if (a.actual === b.actual) return 0
      })
      setUnavailableItem({ item: foodDetails, datesArray: sortedDates })
      setOpenFoodNotAvailable(true)
    } else if (mealDate?.isoString !== '' && mealType?.type === '') {
      openNotificationWithIcon('error', 'Please choose the meal')
    } else if (mealDate?.isoString === '' && mealType?.type !== '') {
      openNotificationWithIcon('error', 'Please choose the date')
    } else {
      if (foodDetails?.productVariants?.length !== 0) {
        setReCustomizeItem({})
        if (mealDate?.isoString !== '') {
          if (mealType?.type === '') {
            openNotificationWithIcon('error', 'Please choose the meal')
          } else {
            setShowCustomisationScreen(true)
            setOpenCustomisationModal(true)
            setCustomisationItem({
              foodDetails,
              quantity:
                cartItems.find(
                  (item: CartItemProps) => item?.foodDetails?.title === foodDetails?.title
                )?.quantity || 1,
              customisation: [],
              extraPrice: 0,
              customisationString: ''
            })
          }
        }
      } else {
        if (mealDate?.isoString !== '' && mealType?.type !== '') {
          setCartItems((prev: any) => [
            ...prev,
            { foodDetails, quantity: 1, customisation: [], extraPrice: 0, customisationString: '' }
          ])
        }
      }
    }
  }

  useEffect(() => {
    let value = 0
    cartItems.map((product: CartItemProps) => {
      if (product?.foodDetails?.title === foodDetails?.title) {
        value += product?.quantity || 0
      }
    })
    setTotalItems(value)
  }, [cartItems, foodDetails])

  return (
    <div className="relative flex w-[100%]">
      {contextHolder}
      <Media at="sm">
        {isContainAllergensOpen && (
          <div className="absolute right-[0px] z-50 mt-[50px] w-[100%] bg-[white] shadow-containsAllergensShadow">
            <ContainsAllergens
              setIsContainAllergensOpen={setIsContainAllergensOpen}
              items={foodDetails}
            />
            <div className="ovarallPop" onClick={() => setIsContainAllergensOpen(false)}></div>
          </div>
        )}
      </Media>
      <Media greaterThan="sm">
        {isContainAllergensOpen && (
          <div className="absolute right-[24px] z-50 mt-[50px] w-[70%] bg-[white] shadow-containsAllergensShadow">
            <ContainsAllergens
              setIsContainAllergensOpen={setIsContainAllergensOpen}
              items={foodDetails}
            />
            <div className="ovarallPop" onClick={() => setIsContainAllergensOpen(false)}></div>
          </div>
        )}
      </Media>
      <div className="flex w-[90%] flex-col py-[24px] pr-[30px] md:py-[16px] ">
        <div className="flex">
          <div className="mt-[3px]">
            <FiltersIconsHandler
              icon={foodDetails?.dishType}
              imageDetails={{ height: width > 768 ? 18 : 15, width: width > 768 ? 18 : 15 }}
            />
          </div>
          <div className="ml-[8px] font-black text-lg font-[500] leading-[24.59px] text-black md:pt-[2px] md:text-xl">
            {foodDetails?.title}
          </div>
        </div>
        <div className="pt-[15px] pb-[8px] font-book text-sm leading-[19.12px] text-black sm:text-md">
          {'1 Katori' + ' | ' + foodDetails?.servingSize}
        </div>

        <div className="pb-[8px] font-black text-sm font-[500] leading-[19.12px] text-black sm:text-md">
          {` ${tenant?.currencySymbol} ${foodDetails?.price}`}
        </div>
        <div
          className={classNames(
            'm-0 p-0 text-justify font-book text-sm leading-[19.12px] text-black-50 sm:text-md',
            showMore ? '' : 'ellipsis'
          )}
        >
          {foodDetails?.description}
        </div>
        {foodDetails?.description?.length > 110 && (
          <div
            className="w-fit cursor-pointer select-none border-[0px] border-b-[1px] border-solid font-black text-sm font-[500] leading-[16.39px] text-black-50"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? 'Less' : 'More'}
          </div>
        )}
        {foodDetails?.likes > 0 && (
          <div className=" ml-[8px] mt-[20px]  flex pr-[10px]">
            <LikeButton
              width="18"
              height="19"
              fillColor={theme?.customPrimary}
              viewBox="0 0 18 19"
            />
            <div className="mt-[1px] pl-[10px] font-book text-black-50">{foodDetails?.likes}</div>
          </div>
        )}
      </div>
      <div className="relative flex flex-col items-center pt-[24px] pb-[32px] ">
        {foodDetails?.productVariants?.map(
          (item: any) =>
            item?.name === 'Allergens' &&
            item?.variantOptions?.length > 0 && (
              <button
                className="mb-[10px] flex items-center"
                onClick={() => setIsContainAllergensOpen(true)}
                key={item?.id}
              >
                <div className="leading-17 mr-[5px] font-book text-xs font-medium">
                  Contains Allergens
                </div>
                <ImageWithBasePath src="info" alt="" width={13} height={13} />
              </button>
            )
        )}
        <div className="pt-[4px]">
          <ImageWithBasePath
            src={foodDetails?.productImages[0]?.image || 'foodItem'}
            isurl={Boolean(foodDetails?.productImages[0]?.image)}
            height={width > 768 ? 107 : 85}
            width={width > 768 ? 157 : 125}
            alt=""
            className="image h-[85px] rounded-[8px]"
            style={{
              objectFit: 'contain',
              width: width > 768 ? 157 : 125,
              height: width > 768 ? 107 : 85
            }}
          />
        </div>
        <div className="mt-[8px] pb-[10px]">
          {isPresent ? (
            <NoOfItemsSelector
              value={totalItems}
              foodDetails={
                cartItems.find(
                  (item: CartItemProps) => item?.foodDetails?.title === foodDetails?.title
                )?.foodDetails
              }
              className="shadow-primaryButtonShadow"
              valueClassName="px-[13px] text-sm leading-[16.39px]"
              style={{ height: '34px', width: '43px' }}
              imageDetails={{ height: 21, width: 21 }}
              item={cartItems.find(
                (item: CartItemProps) => item?.foodDetails?.title === foodDetails?.title
              )}
              forHome={foodDetails?.productVariants?.length === 0}
            />
          ) : (
            <PrimaryButton
              title={
                mealDate?.isoString === '' || mealType?.type === '' ? 'Check Availability' : 'Add'
              }
              className="min-w-[120px] py-[12px] text-sm hover:shadow-primaryButtonShadow sm:drop-shadow-none"
              onClick={primaryButtonHandler}
            />
          )}
        </div>
        {foodDetails?.productVariants?.length > 0 && (
          <div className="cursor-pointer font-book text-xs font-medium leading-[16.39px] text-black-50 sm:text-sm">
            Customisable
          </div>
        )}
      </div>
    </div>
  )
}
