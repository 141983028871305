import React, { useContext } from 'react'
import { AppContext } from './Layout'
import { ImageWithBasePath } from './ImageWithBasePath'

export const EmptyList = ({ kitchenIsActive }: { kitchenIsActive: boolean }) => { 
  const { mealDate } = useContext(AppContext)
  let message = !kitchenIsActive
    ? 'Sorry ,the kitchen is on snooze mode! Check back for tastier times!'
    : mealDate?.isoString !== ''
    ? 'No dishes available for the selected date. Choose a different date or check back later for new menu options.'
    : 'No dishes available. Please select the date.';

  return (
    <div className="mt-[57px] flex flex-col items-center px-[24px] pb-[74px] text-center md:mt-[73px] md:px-[109px]">
      <ImageWithBasePath src="NoDishes" width={227} height={212} />
      <div className="mt-[24px] font-book text-[14px] font-medium">
      {message}
      </div>
    </div>
  )
}
