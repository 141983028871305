import { useRouter } from 'next/router'
import React, { useContext, useEffect } from 'react'
import { AppContext } from './Layout'
import { CartEmpty } from './CartEmpty'
import { cartPriceHandler } from '../utils/functions'
import { DesktopCartItemsShow } from './DesktopCartItemsShow'
import { PrimaryButton } from './PrimaryButton'

export const YourCart = (): JSX.Element => {
  const {
    cartItems,
    cartTotalPrice,
    setCartTotalPrice,
    tenant,
    profileData,
    setIsEditProfileOpen
  } = useContext(AppContext)

  const router = useRouter()

  useEffect(() => {
    const price = cartPriceHandler(cartItems)
    setCartTotalPrice(String(price))
  }, [cartItems])

  return cartItems.length > 0 ? (
    <div>
      <div className="pb-[16px] font-black text-2xl font-[500] leading-[27.32px]">Your Cart</div>
      <DesktopCartItemsShow className="pb-[24px]" />
      <div className="flex items-center justify-between">
        <div className="font-black text-lg font-[500] leading-[28.8px] text-black-100">
          Subtotal
        </div>
        <div className="font-black text-lg font-[500] leading-[21.86px] text-black-100">{`${tenant?.currencySymbol} ${cartTotalPrice}`}</div>
      </div>
      <div className="w-[70%] font-book text-sm leading-[16.8px] text-black-50">
        Other charges will be calculated during checkout
      </div>
      <PrimaryButton
        title="Checkout"
        className="mt-[16px] py-[13.5px] text-2xl"
        onClick={async () => {
          if (profileData?.firstName) {
            await router.push({ pathname: '/cart' })
          } else {
            setIsEditProfileOpen(true)
            router.push({
              // @ts-ignore
              pathname: '/profile',
               query: { addUser: true, previousRoute: '/cart' }
            })
          }
        }}
      />
    </div>
  ) : (
    <CartEmpty />
  )
}
