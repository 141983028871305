import { useRouter } from 'next/router'
import classNames from 'classnames'
import Image from 'next/image'
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from './Layout'
import { getFormattedRatingCount } from '../utils/functions'
import { RightArrow } from '../svgIcons/rightArrow'
import { StarIcon } from '../svgIcons/starIcon'
import { TENANT_DETAILS } from '../ts/interfaces/tenant.interface'

export const RestaurantDetailsCard = ({
  tenant,
  isThresholdDistanceCrossed = false
}: {
  tenant: TENANT_DETAILS
  isThresholdDistanceCrossed?: boolean
}) => {
  const { theme } = useContext(AppContext)
  const router = useRouter()
  const [width, setWidth] = useState<number>(0)

  useEffect(() => {
    window.addEventListener('resize', (e: any) => {
      setWidth(e.target.innerWidth)
    })
  }, [width])

  return (
    <div
      className={classNames(
        'w-[100%] bg-[white]',
        isThresholdDistanceCrossed
          ? 'h-[56px] py-[8px] pl-[24px] transition-all duration-700'
          : 'h-[133px ] fit-content py-[15px] pl-[30px] shadow-cardShadow transition-all duration-300'
      )}
    >
      <div className={classNames('flex items-center')}>
        <div className={classNames(isThresholdDistanceCrossed ? '' : 'w-[220px]')}>
          {tenant?.logo ? (
            <Image
              src={`${tenant?.logo}`}
              width={0}
              height={0}
              alt=""
              style={{
                borderRadius: 11,
                objectFit: "cover", 
                width: isThresholdDistanceCrossed ? "" : "75%",
                height: isThresholdDistanceCrossed?"30%":"auto"
              }}
            />
          ) : null}
        </div>
        <div className="min-w-[150px] pl-[8px] pr-[17px] mr-[17px]">
          <div
            className={classNames(
              'font-black font-[500]',
              isThresholdDistanceCrossed
                ? 'text-2xl leading-[27.32px]'
                : 'text-lg  leading-[21.86px]'
            )}
            style={{
              color: theme?.customPrimary ?? theme.customPrimary ,
              fontSize:isThresholdDistanceCrossed?"100%":"110%"
            }}
          >
            {tenant?.kitchenName}
          </div>
          {!isThresholdDistanceCrossed && (
            <div className="font-book text-[18px] font-medium leading-[15.03px] pt-[12px]">
              {tenant?.tagLine}
            </div>
          )}
        </div>
        {!isThresholdDistanceCrossed && (
          <div className="flex w-[190px] items-center rounded-l-[20px] border-[1px] border-r-[0px] border-solid border-[#F7D61C] bg-yellow-light p-[8px]">
            <div className="">
              <div className="flex items-center">
                <div className="font-black text-sm font-[500] leading-[16.39px] text-[#034744]">
                  {tenant?.averageRating?.toFixed(1)?.split('.')?.length > 1
                    ? tenant?.averageRating?.toFixed(1)
                    : `${tenant?.averageRating?.toFixed(1)}.0`}
                </div>
                <div className="ml-[2px] -mt-[3px]">
                  <StarIcon height="10" width="12" viewBox="0 0 12 12" fillColor="#F7D61C" />
                </div>
              </div>
              <div className="font-book text-[11px] font-medium leading-[16.39px]">
                {Number(getFormattedRatingCount(tenant?.totalReviews)) > 1
                  ? `${getFormattedRatingCount(tenant?.totalReviews)} Ratings`
                  : `${getFormattedRatingCount(tenant?.totalReviews)} Rating`}
              </div>
            </div>
          </div>
        )}
      </div>
      {!isThresholdDistanceCrossed && (
        <div
          className={classNames(
            'mt-[12px] flex w-[90%] items-center',
            width <= 350 ? '' : 'justify-between'
          )}
        >
          <div className="flex flex-wrap items-center">
            {tenant?.cuisinesServed?.map((item: string) => (
              <div
                key={item}
                style={{
                  borderColor: theme?.customPrimary ?? theme.customPrimary,
                  backgroundColor: theme?.custom50 ?? theme.custom50
                }}
                className={classNames(
                  `mb-[5px] mr-[8px] rounded-[18px] border py-[2px] px-[2px] font-roman text-[20px] font-[500] leading-16`,
                  width <= 300 && 'leading-10 mr-[4px] px-[2px] text-sm',
                  width > 300 && width <= 346 && 'leading-10 mr-[7px] px-[2px] text-sm',
                  width === 350 && 'leading-10 mr-[7px] px-[2px] text-sm'
                )}
              >
                {item}
              </div>
            ))}
          </div>
          <div
            className={classNames('mb-[5px]', width < 300 ? '-ml-[5px]' : '')}
            onClick={() => router.push('/about')}
          >
            <RightArrow
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fillColor={theme?.customPrimary ?? theme.customPrimary}
              gradientColor={theme?.customPrimary ?? theme.gradient}
            />
          </div>
        </div>
      )}
    </div>
  )
}
